<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <!-- tanggal pick dari -->
                                <v-col cols="12" xs="12" sm="2" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo
                                        v-model="date_from"
                                        :label="$t('labels.dateFrom')"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details="true"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_from"
                                    type="month"
                                    @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                                </v-col>


                                <!-- tanggal pick sampai -->
                                <v-col cols="12" xs="12" sm="2" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo
                                        v-model="date_to"
                                        :label="$t('labels.dateTo')"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details="true"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_to"
                                    type="month"
                                    @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                                </v-col>

                                <!-- <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-city"
                                        clearable
                                        dense
                                        solo
                                        v-model="province"
                                        :items="provinces"
                                        item-value="province"
                                        :item-text="item => item.province"
                                        label="Province"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getCity(event)"
                                        :disabled="province_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-city"
                                        clearable
                                        dense
                                        solo
                                        v-model="city"
                                        :items="cities"
                                        item-value="city"
                                        :item-text="item => item.city"
                                        label="City"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_city"
                                        @change="(event) => getDistricts(event)"
                                        :disabled="city_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-city"
                                        clearable
                                        dense
                                        solo
                                        v-model="districts"
                                        :items="districtss"
                                        item-value="districts"
                                        :item-text="item => item.districts"
                                        label="Districts"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_districts"
                                        @change="(event) => getVillage(event)"
                                        :disabled="districts_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-city"
                                        clearable
                                        dense
                                        solo
                                        v-model="vilage"
                                        :items="vilages"
                                        item-value="vilage"
                                        :item-text="item => item.vilage"
                                        label="Village"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_vilage"
                                        
                                        :disabled="vilage_disabled"
                                    ></v-autocomplete>
                                </v-col> -->
                                <!-- <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region_proyek"
                                        :items="region_proyeks"
                                        item-value="region_proyek"
                                        :item-text="item => item.region_proyek"
                                        label="Region Proyek"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getRegion(event)"
                                        :disabled="region_proyek_disabled"
                                    ></v-autocomplete>
                                </v-col> -->
                                <!-- <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="report"
                                        :items="reports"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        label="Report Option"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true 
                                        @change="(event) => reportOptionCheck(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region"
                                        :items="regions"
                                        item-value="region"
                                        :item-text="item => item.region"
                                        label="Region"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getOffice(event)"
                                        :disabled="region_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline"
                                        clearable
                                        dense
                                        solo
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id"
                                        :item-text="item => item.office.trim()"
                                        label="Office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_office"
                                        @change="(event) => getDistricts(event)"
                                        :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region"
                                        :items="regions"
                                        item-value="region"
                                        :item-text="item => item.region"
                                        label="Region"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getOffice(event)"
                                        :disabled="region_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline"
                                        clearable
                                        dense
                                        solo
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id"
                                        :item-text="item => item.office.trim()"
                                        label="Office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_office"
                                        :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline"
                                        clearable
                                        dense
                                        solo
                                        v-model="cust_type"
                                        :items="cust_types"
                                        item-value="cust_type"
                                        :item-text="item => item.office.trim()"
                                        label="Cust Type"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :disabled="cust_type_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <!-- <v-col class="col-12 d-flex justify-center" sm="2" md="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col class="d-flex align-center mt-2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 mr-3 ml-2 text-white border-12"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :data="histori_exports"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-row class="mt-2">
            <v-col class="col-12" sm="6" md="12">
                <p class="text-center font-weight-bold"  style="font-size: 22px;">Empty Customer Area</p>
                <v-data-table
                    dense
                    :headers="header_item_empty_area"
                    :items="data_empty_area"
                    class="elevation-1 mt-2"
                    :items-per-page="10"
                    fixed-header
                    :divider="true"
                    :light="true"
                >
                <template v-slot:[`item.TglPesan`]="{ item }">
                {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                </template>
                <template v-slot:[`item.Lembar`]="{ item }">
                    {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
                </template>
                <template v-slot:[`item.HargaSatuan`]="{ item }">
                    {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
                </template>
                <template v-slot:[`item.Total`]="{ item }">
                    {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
                </template>
                <template v-slot:[`item.showDetails`]="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link>
                                <v-list-item-title class="ma-2" @click="showDetailsCustomer(item)">Details</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    
                </template>
                </v-data-table>
            </v-col>
        </v-row> -->
        <v-row class="mt-2">
            <v-col class="col-12" sm="6" md="3" >
                <p class="text-center font-weight-bold"  style="font-size: 22px;">Province Rank</p>
                <v-data-table
                    dense
                    :headers="header_province_rank"
                    :items="data_province_rank"
                    class="elevation-1 mt-2"
                    :items-per-page="10"
                    fixed-header
                    :divider="true"
                    :light="true"
                    @click:row="handleCityClick"
                >
                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:[`item.wgt`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.wgt) }}
                </template>
                <template v-slot:[`item.qty_new`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_new || 0) }}
                </template>
                <template v-slot:[`item.qty_aktif`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_aktif || 0) }}
                </template>
                <!-- <template v-slot:[`item.showCity`]="{ item }">
                    <v-btn icon @click="handleCityClick(item)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template> -->
                </v-data-table>
            </v-col>
            <v-col class="col-12" sm="6" md="3">
                <p class="text-center font-weight-bold"  style="font-size: 22px;">City Rank</p>
                <v-data-table
                    dense
                    :headers="header_city_rank"
                    :items="data_city_rank"
                    class="elevation-1 mt-2"
                    :items-per-page="10"
                    fixed-header
                    :divider="true"
                    :light="true"
                    @click:row="handleDistrictClick"
                >
                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:[`item.wgt`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.wgt) }}
                </template>
                <template v-slot:[`item.qty_new`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_new || 0) }}
                </template>
                <template v-slot:[`item.qty_aktif`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_aktif || 0) }}
                </template>
                <!-- <template v-slot:[`item.showDistrict`]="{ item }">
                    <v-btn icon @click="handleDistrictClick(item)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template> -->
                </v-data-table>
            </v-col>
            <v-col class="col-12" sm="6" md="3" >
                <p class="text-center font-weight-bold"  style="font-size: 22px;">District Rank</p>
                <v-data-table
                    dense
                    :headers="header_district_rank"
                    :items="data_district_rank"
                    class="elevation-1 mt-2"
                    :items-per-page="10"
                    fixed-header
                    :divider="true"
                    :light="true"
                    @click:row="handleVilageClick"
                >
                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:[`item.wgt`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.wgt) }}
                </template>
                <template v-slot:[`item.qty_new`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_new || 0) }}
                </template>
                <template v-slot:[`item.qty_aktif`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_aktif || 0) }}
                </template>
                <!-- <template v-slot:[`item.showVilage`]="{ item }">
                    <v-btn icon @click="handleVilageClick(item)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template> -->
                </v-data-table>
            </v-col>
            <v-col class="col-12" sm="6" md="3">
                <p class="text-center font-weight-bold"  style="font-size: 22px;">Village Rank</p>
                <v-data-table
                    dense
                    :headers="header_vilage_rank"
                    :items="data_vilage_rank"
                    class="elevation-1 mt-2"
                    :items-per-page="10"
                    fixed-header
                    :divider="true"
                    :light="true"
                    @click:row="handleGraphClick"
                >
                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:[`item.wgt`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.wgt) }}
                </template>
                <template v-slot:[`item.qty_new`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_new || 0) }}
                </template>
                <template v-slot:[`item.qty_aktif`]="{ item }">
                    {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.qty_aktif || 0) }}
                </template>
                <!-- <template v-slot:[`item.showGraph`]="{ item }">
                    <v-btn icon @click="handleGraphClick(item)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template> -->
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <!-- <v-card outlined class="p-0"> -->
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="4">
                                <v-card outlined height="430px">
                                    <v-card-text>
                                        <v-img
                                        class="text-center mx-auto mt-12"
                                        lazy-src="@/assets/empty_data.png"
                                        max-width="200"
                                        src="@/assets/empty_data.png"
                                        v-if="showData"
                                        ></v-img>
                                        <div id="container1" style="height: 370px; width: 100%;"></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="col-12" sm="6" md="8">
                                <v-card class="pa-md-5" outlined height="430px">   
                                    <div id="cust_growth" style="height: 370px; width: 100%;"></div>
                                    <p></p>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" sm="6" md="4">
                                <v-card outlined height="430px">
                                    <v-card-text>
                                        <v-img
                                        class="text-center mx-auto mt-12"
                                        lazy-src="@/assets/empty_data.png"
                                        max-width="200"
                                        src="@/assets/empty_data.png"
                                        v-if="showData"
                                        ></v-img>
                                        <div id="container2" style="height: 370px; width: 100%;"></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="col-12" sm="6" md="8">
                                <v-card class="pa-md-5" outlined height="430px">   
                                    <div id="weight_growth" style="height: 370px; width: 100%;"></div>
                                    <p></p>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                <!-- </v-card> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span>
                    <p></p>
                </span>
            </v-col>
        </v-row>
        <v-dialog
        v-model="dialogDetailItem"
        persistent
        min-width="650"
        max-width="1000"
        >
            <v-card height="600" style="overflow: auto;">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-0">
                                Detil Customer
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                class="mx-2"
                                icon
                                x-small
                                color="#e83e8c;"
                                @click="[dialogDetailItem = false]"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="">
                    <v-card flat>
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12 py-0" md="6">
                                    <v-container fluid>
                                        <v-row> 
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Provinsi</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">{{ DetailProvinsi ? DetailProvinsi : "-" }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Kecamatan</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">{{ DetailKecamatan ? DetailKecamatan : "-" }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col class="col-12 py-0" md="6">
                                    <v-container fluid>
                                        <v-row> 
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Kota</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">{{DetailKota ? DetailKota : "-" }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Kelurahan/Desa</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">{{DetailVilage ? DetailVilage : "-" }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>                                
                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="header_detil_customer"
                                        :items="data_detil_customer"
                                        class="elevation-1 font-12"
                                        :items-per-page="30"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="loadingDatatableDetail"
                                        height="250"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                    >  
                                        <template v-slot:[`item.index`]="{ index }">
                                            {{ index + 1 }}
                                        </template>
                                    </v-data-table>  
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        @click="dialogDetailItem = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'E-Reports',
                    disabled: true,
                },
                {
                    text: 'Customer Growth',
                    disabled: true,
                }
            ],
            regions: [],
            region: '',
            offices: [],
            office: '',
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            consignes: [],
            consigne: '',
            divisions: [
                {
                    value: 'all',
                    text: 'All'
                },
                {
                    value: 'RETAIL',
                    text: 'RETAIL'
                },
                {
                    value: 'PROYEK',
                    text: 'PROYEK'
                }
            ],
            report:'',
            reports: [
                {
                    value: 'RO',
                    text: 'Region-Office'
                },
                {
                    value: 'A',
                    text: 'Area'
                }
            ],
            division: 'all',
            modal: false,
            modal_to: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            histories: [],
            headers: [],
            headersColumn: {},
            months: [],
            loading_office: false,
            loading_sales: false,
            loading_customer: false,
            loading_consigne: false,
            region_disabled: false,
            office_disabled: false,
            sales_disabled: false,
            division_disabled: false,
            searchCustomer: null,
            histori_exports: [],
            region_proyek: '',
            region_proyeks:[],
            region_proyek_disabled: false,
            selectedRows:[],
            dialogDetailItem: false,
            DetailCreditLimitModel: '',
            DetailPiutangModel: '',
            DetailSisaCreditLimitodel: '',
            DetailOutsOrderModel: '',
            DetailLastVisitModel: '',
            DetailTotalWgtModel: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'NO.', value: 'index', align: 'left', class: 'primary--text blue lighten-5', width: '5%', sortable: false },
                { text: 'PROD. CODE', value: 'BarangJadiId', align: 'left', class: 'primary--text blue lighten-5', width: '10%', sortable: false },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', width: '70%', sortable: false },
                { text: 'WEIGHT(KG)', value: 'berat', align: 'right', class: 'primary--text blue lighten-5', width: '5%', sortable: false },
            ],
            loadingDatatableDetail: false,
            tab: 'tab-1',
            summaryInv: null,
            province:'',
            provinces:[],
            province_disabled:false,
            city:'',
            cities:[],
            city_disabled:false,
            districts:'',
            districtss:[],
            districts_disabled:false,
            vilage:'',
            vilages:[],
            vilage_disabled:false,
            showData: true,
            active_custs: [],
            growth_custs: [],
            data_empty_area: [],
            data_province_rank:[],
            data_city_rank:[],
            data_district_rank:[],
            data_vilage_rank:[],
            data_detil_customer:[],
            header_item_empty_area:[
                // {
                //     text: 'Province',
                //     align: 'start',
                //     sortable: true,
                //     value: 'province',
                // },
                { text: 'City', value: 'city' , class: 'primary--text blue lighten-5'},
                { text: 'Districts', value: 'districts' , class: 'primary--text blue lighten-5'},
                { text: 'Vilage', value: 'vilage' , class: 'primary--text blue lighten-5'},
                { text: 'New Customer', value: 'qty_new' , class: 'primary--text blue lighten-5'},
                { text: 'Aktif Customer', value: 'qty_aktif' , class: 'primary--text blue lighten-5'},
                // { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            header_province_rank:[
                { text: 'Province', value: 'province' , class: 'primary--text blue lighten-5'},
                { text: 'New Cust', value: 'qty_new' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Actv Cust', value: 'qty_aktif' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Total Cust', value: 'total_cust' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Weight (Ton)', value: 'wgt' , align: 'right' , class: 'primary--text blue lighten-5'},
                // { text: '', value: 'showCity', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            header_city_rank:[
                { text: 'City', value: 'city' , class: 'primary--text blue lighten-5'},
                { text: 'New Cust', value: 'qty_new' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Actv Cust', value: 'qty_aktif' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Total Cust', value: 'total_cust' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Weight (Ton)', value: 'wgt' , align: 'right' , class: 'primary--text blue lighten-5'},
                // { text: '', value: 'showDistrict', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            header_district_rank:[
                { text: 'Districts', value: 'districts' , class: 'primary--text blue lighten-5'},                
                { text: 'New Cust', value: 'qty_new' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Actv Cust', value: 'qty_aktif' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Total Cust', value: 'total_cust' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Weight (Ton)', value: 'wgt' , align: 'right' , class: 'primary--text blue lighten-5'},
                // { text: '', value: 'showVilage', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            header_vilage_rank:[
                { text: 'Village', value: 'vilage' , class: 'primary--text blue lighten-5'},                
                { text: 'New Cust', value: 'qty_new' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Actv Cust', value: 'qty_aktif' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Total Cust', value: 'total_cust' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Weight (Ton)', value: 'wgt' , align: 'right' , class: 'primary--text blue lighten-5'},
                // { text: '', value: 'showGraph', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            header_detil_customer:[
                { text: 'Customer Id', value: 'CustomerId' , class: 'primary--text blue lighten-5'},                
                { text: 'Nama Customer', value: 'NamaCustomer' , class: 'primary--text blue lighten-5'},
                { text: 'Alamat', value: 'Alamat' , class: 'primary--text blue lighten-5'},
                { text: 'Telepon', value: 'Telepon' , align: 'right' , class: 'primary--text blue lighten-5'},
                { text: 'Nama Owner', value: 'owner_name' , class: 'primary--text blue lighten-5'},
                { text: 'Alamat Owner', value: 'owner_address' , class: 'primary--text blue lighten-5'},                
                { text: 'Telepon Owner', value: 'owner_phone' , class: 'primary--text blue lighten-5'},                
                { text: 'Nama Sales', value: 'NamaSales' , align: 'right' , class: 'primary--text blue lighten-5'},
            ],
            cust_type:'',
            cust_types: [
                'RETAIL','PROJECT'
            ],
            cust_type_disabled:false,
        }
    },
    computed:{
    },
    watch: {
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        // this.getMonth(this.date_from, 12)
        
        await this.getEnvConf()
        // await this.getRegionProyek()
        // await this.getOffice('KMB')
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.office = ''
            }
            if (this.sales_disabled == false) {
                this.sales = ''
            }
            if (this.division_disabled == false) {
                this.division = ''
            }

            this.date_from =''
            this.date_to=''
        },
        async getEnvConf(){
            console.log('group ID : '+this.$store.state.kmb.group_id);
            
            if (this.$store.state.kmb.group_id == 'RM KMB') {
                const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGION&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
                
                if (respDataRegion.status == 200) {
                    this.region = respDataRegion.data.data ? respDataRegion.data.data : ''
                    this.region_disabled = respDataRegion.data.data ? true : false
                    // if (this.region_disabled) {
                        // this.province_disabled = true
                        // this.city_disabled = true
                        // await this.getRegionProyek()
                        // await this.getRegion(this.region)
                        // await this.getOffice(this.region)
                    // }
                    // console.log('Region : '+this.region);
                    // await this.getProvince()

                    this.getRegion(this.region)
                    this.getOffice(this.region)
                }
            }

            if (this.$store.state.kmb.group_id == 'KACAB KMB') {
                const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
                
                if (respDataOffice.status == 200) {
                    this.office = respDataOffice.data.data ? respDataOffice.data.data : ''
                    this.office_disabled = respDataOffice.data.data ? true : false
                    // if (this.office_disabled) {
                    //     this.region_disabled = true
                    //     this.province_disabled = true
                    //     this.city_disabled = true

                    //     await this.getRegion(this.office)
                    //     await this.getOffice(this.office)
                    //     await this.getDistricts(this.office)
                        
                    // } else {
                    //     await this.getOffice('KMB')
                    // }
                    // await this.getProvince()
                }
            }

            if (this.$store.state.kmb.group_id == 'MANAGEMENT' || this.$store.state.kmb.group_id == 'DEVELOPMENT') {
                // await this.getRegion('KMB')
                // await this.getOffice('KMB')
                this.getRegion()
                this.getOffice()
            }
        },
        async getRegion(event){
            if (event == '' || event == null) {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/region?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.regions = res.data.data
                    if (this.region_disabled && res.data.data.length > 0) {
                        this.region = res.data.data[0].region
                        this.region_disabled = true
                    } else {
                        this.region = ''
                        this.region_disabled = false
                    }
                    
                });
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/region?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.regions = res.data.data
                    if (this.region_disabled && res.data.data.length > 0) {
                        this.region = res.data.data[0].region
                        this.region_disabled = true
                    } else {
                        this.region = ''
                        this.region_disabled = false
                    }
                    
                });
            }
        },
        async getOffice(event){
            if (event == '' || event == null) {
                this.office = ''
                this.region = ''
            } else {
                this.loading_office = true
                this.offices = []
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/office?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_office = false
                    this.offices = res.data.data
                });
            }
        },
        async getProvince(event){
            this.provinces = []
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getProvince`, {

            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.provinces  = res.data.data
            })
        },
        async getCity(event){
            if (event == '' || event == null) {
                this.city = ''
                this.districts = ''
                this.vilage = ''
            } else {
                this.loading_city = true
                this.cities = []

                await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getCity`, {
                    province : this.province ? this.province : ""
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_city = false
                    this.cities = res.data.data
                });
            }
        },
        async getDistricts(event){
            if (event == '' || event == null) {
                this.districts = ''
                this.vilage = ''
            } else {
                if(this.city_disabled==true){
                    this.loading_districts = true
                    this.districtss = []

                    await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getDistrict`, {
                        city_id : this.office ? this.office : ""
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    })
                    .then(res => {
                        this.loading_districts = false
                        this.districtss = res.data.data
                    });
                }else{
                    this.loading_districts = true
                    this.districtss = []

                    await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getDistrict`, {
                        city : this.city ? this.city : ""
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    })
                    .then(res => {
                        this.loading_districts = false
                        this.districtss = res.data.data
                    });
                }
            }
        },
        async getVillage(event){
            if (event == '' || event == null) {
                this.vilage = ''
            } else {
                this.loading_vilage = true
                this.vilages = []

                await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getVillage`, {
                    districts : this.districts ? this.districts : ""
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_vilage = false
                    this.vilages = res.data.data
                });
            }
        },
        
        async searchFunction() {
            if (this.date_from === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Period',
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', true);  

                try {
                    await this.getProvinceRank();
                    await this.getColumnChart();
                    await this.getActiveNewCustAll();
                    this.data_city_rank = [];
                    this.data_district_rank = [];
                    this.data_vilage_rank = [];
                } catch (error) {
                    console.error("An error occurred:", error);
                } finally {
                    this.$store.dispatch('setOverlay', false);
                }
            }
        },


        async exportExcel(){
            if (this.date_from === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Date From/ Date To',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }
                        
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getExportExcel`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '' ,
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.histori_exports  = res.data.data
            })
            return this.histori_exports
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        getRowData(item, index, event) {
            // console.log('Clicked row:', item);
            // console.log('index', index);
            // console.log('event', event);
            // // Get the column header of the clicked cell
            // const columnHeader = event.target.closest('th').textContent.trim();
            // // Get the value of the clicked cell
            // const cellValue = item[columnHeader.toLowerCase()];
            // console.log(`Selected value in ${columnHeader}: ${cellValue}`);
        },

        async openDialog(item, head){

            console.log("item :", item)
            console.log("head :", head)
            console.log("customerId :", item["CustomerId"])
            console.log("value :", item[head])

            this.itemDetailLists = []
            this.DetailCreditLimitModel = 0
            this.DetailPiutangModel = 0
            this.DetailSisaCreditLimitodel = 0
            this.DetailOutsOrderModel = 0
            this.DetailLastVisitModel = '-'
            this.DetailTotalWgtModel = 0
            this.dialogDetailItem = true
            this.loadingDatatableDetail = true
            await this.summary(item)
            await axios.post(`${process.env.VUE_APP_URL}/v2/kmb/order_consignee/getDetail`, { 
                cust_id: item["CustomerId"],
                period: head
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.resultItem
                this.DetailCreditLimitModel = this.formatNumber(res.data.resultHeader.cl_amt)
                this.DetailPiutangModel = this.formatNumber(res.data.resultHeader.piutang_amt)
                this.DetailSisaCreditLimitodel = this.formatNumber(res.data.resultHeader.cl_amt_sisa)
                this.DetailOutsOrderModel = this.formatNumber(res.data.resultHeader.out_amt)
                this.DetailLastVisitModel = res.data.resultHeader.last_visit
                this.DetailTotalWgtModel = item[head]

            })
            .catch(err => {
                console.log(err)
            })

            

        },

        async summary(item){

            var reqBody = {
                'cust_id': item["CustomerId"] ? item["CustomerId"] : '',
                'SalesId': this.sales ? this.sales : '',
                'byWhat': ''
            }

            const respData = await backendApi.fetchCore('/api/v3/kmb/finance/piutang/summary-total', reqBody, false, false, false)

            if(respData.status === 200){
                console.log(respData.data);
                this.summaryInv = respData.data.data.length > 0 ? respData.data.data[0] : NULL
            }
        },

        renderChartActiveCust2(datas, datas2, item) {
        if (!datas || datas.length === 0 || !datas2 || datas2.length === 0) {
            console.warn('No data to render chart');
            return;
        }

        const chart = new CanvasJS.Chart("container1", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "Active Customer" + 
                    (item.province ? " - " + item.province : '') + 
                    (item.city ? " - " + item.city : '') + 
                    (item.districts ? " - " + item.districts : '') + 
                    (item.vilage ? " - " + item.vilage : ''),
                fontFamily: "Calibri",
                fontSize: 22
            },
            data: [{
            type: "pie",
            startAngle: 25,
            toolTipContent: "<b>{label}</b>:  {y_s}",
            showInLegend: true,
            legendText: "{label}",
            indexLabelFontSize: 12,
            indexLabel: "{label} - {y}%",
            dataPoints: datas // Passing the data to the pie chart
            }]
        });

        const chart2 = new CanvasJS.Chart("container2", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "New Customer" + 
                    (item.province ? " - " + item.province : '') + 
                    (item.city ? " - " + item.city : '') + 
                    (item.districts ? " - " + item.districts : '') + 
                    (item.vilage ? " - " + item.vilage : ''),
                fontSize: 22
            },
            data: [{
                type: "pie",
                startAngle: 25,
                toolTipContent: "<b>{label}</b>:  {y_s}",
                showInLegend: true,
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints: datas2.map(dataPoint => {
                    return {
                        ...dataPoint,
                        color: dataPoint.label === "Existing Customer" ? "#8fa9ef" : "#87ea63" // Set custom colors based on label
                    };
                })
            }]
        });

        chart.render();
        chart2.render();
        },

        renderChartActiveCust(item) {
        if (!item || item.length === 0) {
            console.warn('No data to render chart');
            return;
        }

        const data_active_passive = [];
        const data_new_exist = [];

        // Active Cust data point
        if (item.qty_aktif > 0) {
            data_active_passive.push({
                y: item.qty_aktif, 
                y_2: (item.qty_aktif / item.total_cust * 100).toFixed(2), 
                label: "Active Cust"
            });
        }

        // Passive Cust data point
        if ((item.total_cust - item.qty_aktif) > 0) {
            data_active_passive.push({
                y: item.total_cust - item.qty_aktif, 
                y_2: ((item.total_cust - item.qty_aktif) / item.total_cust * 100).toFixed(2), 
                label: "Passive Cust"
            });
        }

        // New Cust data point
        if (item.qty_new > 0) {
            data_new_exist.push({
                y: item.qty_new, 
                y_2:(item.qty_new/item.total_cust*100).toFixed(2), 
                label: "New Cust"
            });
        }

        // Exist Cust data point
        if ((item.total_cust - item.qty_new) > 0) {
            data_new_exist.push({
                y: item.total_cust-item.qty_new, 
                y_2:((item.total_cust-item.qty_new)/item.total_cust*100).toFixed(2), 
                label: "Existing Cust"
            });
        }

        const chart = new CanvasJS.Chart("container1", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "Active Customer" + 
                    (item.province ? " - " + item.province : '') + 
                    (item.city ? " - " + item.city : '') + 
                    (item.districts ? " - " + item.districts : '') + 
                    (item.vilage ? " - " + item.vilage : ''),
                fontFamily: "Calibri",
                fontSize: 22
            },
            data: [{
            type: "pie",
            startAngle: 25,
            toolTipContent: "<b>{label}</b>:  {y}",
            showInLegend: true,
            legendText: "{label}",
            indexLabelFontSize: 12,
            indexLabel: "{label} - {y_2}%",
            dataPoints: data_active_passive
            }]
        });

        const chart2 = new CanvasJS.Chart("container2", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "New Customer" + 
                    (item.province ? " - " + item.province : '') + 
                    (item.city ? " - " + item.city : '') + 
                    (item.districts ? " - " + item.districts : '') + 
                    (item.vilage ? " - " + item.vilage : ''),
                fontSize: 22
            },
            data: [{
                type: "pie",
                startAngle: 25,
                toolTipContent: "<b>{label}</b>:  {y}",
                showInLegend: true,
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y_2}%",
                dataPoints: data_new_exist
            }]
        });

        chart.render();
        chart2.render();
        },

        renderChartCustGrowth(datas, datas2, item) {
            if (!datas.length) {
                console.error("No data available for chart rendering.");
                return; 
            }

            console.log("Customer Growth Data:", datas);

            var chart3 = new CanvasJS.Chart("cust_growth", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "New Customer Growth Data" + 
                                (item.province ? " - " + item.province : '') + 
                                (item.city ? " - " + item.city : '') + 
                                (item.districts ? " - " + item.districts : '') + 
                                (item.vilage ? " - " + item.vilage : ''),
                          fontFamily: "Calibri",
                          fontSize: 22
                      },
                      axisX:{
                        title : "Year-Month"
                      },
                      axisY: {
                        title: "Customer",
                        titleFontColor: "#4F81BC",
                        lineColor: "#4F81BC",
                        labelFontColor: "#4F81BC",
                        tickColor: "#4F81BC",
                        includeZero: false
                      },
                      data: [
                      {
                        type: "column",
                        name: "Customer",
                        // click: this.onClickDetailSalesYear,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#f79b07",
                        yValueFormatString: "###,###\"\"",
                        indexLabel: "{y}",
                        dataPoints: datas
                      },
                      ]
                    });
            chart3.render();

            var chart4 = new CanvasJS.Chart("weight_growth", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Weight Order Growth Data" + 
                                (item.province ? " - " + item.province : '') + 
                                (item.city ? " - " + item.city : '') + 
                                (item.districts ? " - " + item.districts : '') + 
                                (item.vilage ? " - " + item.vilage : ''),
                          fontFamily: "Calibri",
                          fontSize: 22
                      },
                      axisX:{
                        title : "Year-Month"
                      },
                      axisY: {
                        title: "Ton",
                        titleFontColor: "#4F81BC",
                        lineColor: "#4F81BC",
                        labelFontColor: "#4F81BC",
                        tickColor: "#4F81BC",
                        includeZero: false
                      },
                      data: [
                      {
                        type: "column",
                        name: "Weight Order",
                        // click: this.onClickDetailSalesYear,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#88a3ee",
                        yValueFormatString: "###,###.00\"\"",
                        indexLabel: "{y} Ton",
                        toolTipContent: "{label}: {y} Ton",
                        dataPoints: datas2
                      },
                      ]
                    });
            chart4.render();
        },

        async getProvinceRank(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getProvinceRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': this.province ? this.province : '',
                'city': this.city ? this.city : '',
                'districts': this.districts ? this.districts : '',
                'vilage': this.vilage ? this.vilage : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_province_rank  = res.data.data
            })
        },
        async getCityRank(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getCityRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': this.province ? this.province : '',
                'city': this.city ? this.city : '',
                'districts': this.districts ? this.districts : '',
                'vilage': this.vilage ? this.vilage : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_city_rank  = res.data.data
            })
        },
        async getDistrictRank(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getDistrictRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': this.province ? this.province : '',
                'city': this.city ? this.city : '',
                'districts': this.districts ? this.districts : '',
                'vilage': this.vilage ? this.vilage : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_district_rank  = res.data.data
            })
        },

        async showDetailsCustomer(item){
            this.dialogDetailItem = true
            this.data_detil_customer = []
            this.loadingDatatableDetail = true
            this.DetailProvinsi = item.province
            this.DetailKota = item.city
            this.DetailKecamatan = item.districts
            this.DetailVilage = item.vilage 

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getDetailCustomer`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province,
                'city': item.city,
                'districts': item.districts,
                'vilage': item.vilage 
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_detil_customer  = res.data.data
                this.loadingDatatableDetail = false
            })
        },

        async showDetailsCity(item){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getCityRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province,
                'city': item.city,
                'districts': item.districts,
                'vilage': item.vilage,
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_city_rank  = res.data.data
            })
        },

        async showDetailsDistrict(item){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getDistrictRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province,
                'city': item.city,
                'districts': item.districts,
                'vilage': item.vilage ,
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_district_rank  = res.data.data
            })
        },

        async showDetailsVilage(item){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getVilageRank`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province,
                'city': item.city,
                'districts': item.districts,
                'vilage': item.vilage ,
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '',
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_vilage_rank  = res.data.data
            })
        },

        async getEmptyArea(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getEmptyArea`, {
                    'date_from': this.date_from ? this.date_from : '',
                    'date_to': this.date_to ? this.date_to : '',
                    'province': this.province ? this.province : '',
                    'city': this.city ? this.city : '',
                    'districts': this.districts ? this.districts : '',
                    'vilage': this.vilage ? this.vilage : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_empty_area  = res.data.data
            })
        },
        
        async handleCityClick(item) {
            this.$store.dispatch('setOverlay', true);  

            try {
                this.data_city_rank=[]
                this.data_district_rank=[]
                this.data_vilage_rank=[]
                await this.showDetailsCity(item);
                await this.getPieChart(item);
                await this.getColumnChart(item);
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                // Always hide the overlay when the operation completes
                this.$store.dispatch('setOverlay', false);
            }
        },
        async handleDistrictClick(item) {
            this.$store.dispatch('setOverlay', true);  

            try {
                this.data_district_rank=[]
                this.data_vilage_rank=[]  
                await this.showDetailsDistrict(item);
                await this.getPieChart(item);
                await this.getColumnChart(item);
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                // Always hide the overlay when the operation completes
                this.$store.dispatch('setOverlay', false);
            }
        },
        async handleVilageClick(item) {
            this.$store.dispatch('setOverlay', true);  

            try {
                this.data_vilage_rank=[]  
                await this.showDetailsVilage(item);
                await this.getPieChart(item);
                await this.getColumnChart(item);
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                // Always hide the overlay when the operation completes
                this.$store.dispatch('setOverlay', false);
            }
        },
        async handleGraphClick(item) {
            this.$store.dispatch('setOverlay', true);  

            try {
                await this.getPieChart(item);
                await this.getColumnChart(item);
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                // Always hide the overlay when the operation completes
                this.$store.dispatch('setOverlay', false);
            }
        },

        // async getPieChart(item){
        //     this.active_custs = []
        //     await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getActiveCust`, {
        //         'date_from': this.date_from ? this.date_from : '',
        //         'date_to': this.date_to ? this.date_to : '',
        //         'province': item.province,
        //         'city': item.city ,
        //         'districts': item.districts,
        //         'vilage': item.vilage 
        //     }, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     })
        //     .then(res => {
        //         this.active_custs  = res.data.data.aktifPasif
        //         this.new_custs  = res.data.data.aktifNew
        //         this.showData = false
        //         // this.$store.dispatch('setOverlay', false)  
        //         // this.renderChartActiveCust(this.active_custs, this.new_custs);
        //     })
        //     this.renderChartActiveCust(this.active_custs, this.new_custs, item);
        // },

        async getPieChart(item){
            this.showData = false
            this.renderChartActiveCust(item);
        },

        async getColumnChart(item = {}){
            this.growth_custs = []
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getGrowthCust`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province ? item.province : '',
                'city': item.city ? item.city : '',
                'districts': item.districts ? item.districts : '',
                'vilage': item.vilage ? item.vilage : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '' ,
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.growth_custs = res.data.custGrowth;
                this.wgt_custs = res.data.wgtGrowth;
            })
            this.renderChartCustGrowth(this.growth_custs, this.wgt_custs, item);
        },

        async getActiveNewCustAll(item = {}){
            this.active_custs = []
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/customergrowth/getActiveNewCustAll`, {
                'date_from': this.date_from ? this.date_from : '',
                'date_to': this.date_to ? this.date_to : '',
                'province': item.province ? item.province : '',
                'city': item.city ? item.city : '',
                'districts': item.districts ? item.districts : '',
                'vilage': item.vilage ? item.vilage : '',
                'region': this.region ? this.region : '',
                'office': this.office ? this.office : '' ,
                'cust_type' : this.cust_type ? this.cust_type : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.active_custs  = res.data.data.aktifPasif
                this.new_custs  = res.data.data.aktifNew
                this.showData = false
                // this.$store.dispatch('setOverlay', false)  
                // this.renderChartActiveCust(this.active_custs, this.new_custs);
            })
            this.renderChartActiveCust2(this.active_custs, this.new_custs, item);
        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },    
}
</script>